@import "styles/_utility";
@import "styles/_colors";

#skills {
	display: flex;
	overflow-x: scroll;
	gap: 1rem;
	scroll-behavior: smooth;
	scroll-snap-type: x;
	&::-webkit-scrollbar {
		width: 1em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: darken($grey, 50%);
		border-radius: 8px;
	}
	.area {
		display: flex;
		padding: 0 0 15px 0;
		gap: 0.5rem;
		h3 {
			font-size: 1.1em;
			letter-spacing: 3px;
			text-transform: uppercase;
			margin-bottom: 0;
			width: 60px;
			height: 250px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $green-dark;
			position: sticky;
			left: 0;
			z-index: 1;
			span {
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 250px;
				transform-origin: bottom left;
				transform: rotate(-90deg) translateY(50%);
			}
		}
	}
}
@media screen and (max-width: 768px) {
	#skills {
		.area {
			font-size: 0.8em;
		}
	}
}
