@import "styles/_utility";
@import "styles/_colors";

#navBar {
  margin-left: auto;
  .menu {
    display: none;
  }
  .menuList {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      list-style-type: none;
      cursor: pointer;
      padding: 15px 10px;
      position: relative;
      font-weight: lighter;
      letter-spacing: 3px;
      margin: 0 5px;
      font-size: 0.9em;
      color: $grey;
      &::before,
      &::after {
        content: "";
        display: block;
        height: 1px;
        background: $light;
        width: 0px;
        position: absolute;
      }
      &::before {
        top: 10px;
        right: 0;
        transition: width ease-in-out 0.2s 0.1s;
      }
      &::after {
        bottom: 10px;
        left: 0;
        transition: width ease-in 0.2s;
      }
      &:hover::before,
      &:hover::after {
        width: 100%;
      }
      &:hover {
        color: $light;
      }
      a {
        color: $grey;
        text-decoration: none;
        &:hover {
          color: $light;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #navBar {
    width: 100vw;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    .menu {
      font-size: 1.5em;
      display: inline-block;
      margin-left: auto;
      margin-right: 15px;
      z-index: 3;
    }
    .menuList {
      background: $dark;
      height: 100vh;
      position: absolute;
      left: 100vw;
      padding-top: 60px;
      top: 0;
      width: 100vw;
      transition: left 0.3s ease-in;
      flex-direction: column;
      justify-content: flex-start;
      z-index: 2;
      background: linear-gradient(
        -33deg,
        $dark,
        $green-darker,
        $purple-darker,
        $dark
      );
      &.show {
        left: 0;
      }
      li {
        text-align: center;
      }
    }
  }
}
