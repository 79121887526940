@import "styles/_utility";
@import "styles/_colors";

#header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1;
  transition: all 1s;

  &.scrolling {
    background: rgba($dark, 0.7);
  }
  .logo {
    height: calcRem(32px);
    margin: calcRem(15px);
  }
}
