@import "styles/_utility";
@import "styles/_colors";

.dots {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: auto 0 0 0;
  padding: 0;
  color: $purple;
  svg {
    font-size: 1.5em;
    filter: createShadow(1px, "svg");
  }
  .dot {
    cursor: pointer;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: $purple;
    box-shadow: createShadow(1px, "box");
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 7px;
    &.active::after {
      content: "";
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background: #7d98a1;
    }
  }
  .previous,
  .next {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .dots {
    justify-content: center;
  }
}
