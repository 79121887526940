@import "./_utility";
@import "./_colors";
@import url("https://fonts.googleapis.com/css?family=Big+Shoulders+Display|Nunito+Sans&display=swap");
html {
  scroll-behavior: smooth;
}
div,
section,
header,
nav {
  position: relative;
}
h1,
h2,
h3 {
  font-family: "Big Shoulders Text", sans-serif;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 1.8rem;
}
body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 rem(6px) rgba(0, 0, 0, 0.3);
  background-color: darken($grey, 40%);
}

body::-webkit-scrollbar-thumb {
  background-color: darken($grey, 50%);
}

.App {
  background: linear-gradient(
    -33deg,
    $dark,
    $green-darker,
    $purple-darker,
    $dark
  );
  color: $light;
  font-family: "Nunito Sans", sans-serif;
  overflow: hidden;
}
