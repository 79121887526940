@import "styles/_utility";
@import "styles/_colors";

#contact {
  height: 100vh;
  position: relative;
  .band {
    top: 200px;
    width: 100vw;
    height: calcRem(400px);
    background: $green;
    transform: skewY(-6deg);
    position: absolute;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.9),
      -6px -6px 12px rgba(0, 0, 0, 0.9);
  }
  h1 {
    padding-top: 80px;
    text-align: right;
  }
  .paper-airplane {
    position: absolute;
    top: 200px;
    right: 100px;
    width: 60vw;
    max-width: 800px;
  }
}

@media screen and (max-width: 768px) {
  #contact {
    .band {
      height: 30rem;
    }
    .paper-airplane {
      position: absolute;
      top: 450px;
      right: 0;
      width: 100vw;
      height: auto;
    }
  }
}
