@import "styles/_utility";
@import "styles/_colors";

#projects {
  min-height: 100vh;
  position: relative;
  .band {
    top: 200px;
    width: 100vw;
    height: calcRem(400px);
    background: $purple;
    transform: skewY(6deg);
    position: absolute;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.9),
      -6px -6px 12px rgba(0, 0, 0, 0.9);
  }
  h1 {
    padding-top: 80px;
  }
  .project {
    margin-top: 60px;
    .info {
      position: absolute;
      left: 0;
      top: 40px;
      width: 40%;
      height: 250px;
      display: flex;
      flex-direction: column;
      padding-right: 30px;
      h2 {
        width: fit-content;
        display: flex;
        align-items: center;
        a {
          font-size: 0.7em;
          color: $purple;
          filter: createShadow(1px, "svg");
        }
      }
      .desc {
        color: $grey;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  #projects {
    .project {
      .info {
        width: 30%;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  #projects {
    .project {
      .info {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #projects {
    .band {
      height: 30rem;
    }
    .project {
      display: flex;
      flex-direction: column;
      .info {
        width: 100%;
        position: relative;
        order: 1;
        height: auto;
        margin-bottom: 60px;
        padding-right: 0px;
        .desc {
          margin-bottom: 20px;
        }
      }
    }
  }
}
