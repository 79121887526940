$dark: #060606;
$light: #fff;

$grey: #aaa;
$grey-light: lighten($grey, 10%);
$grey-dark: darken($grey, 40%);
$grey-darker: darken($grey, 50%);

$green: #344950;
$green-light: lighten($green, 10%);
$green-lighter: lighten($green, 20%);
$green-dark: darken($green, 10%);
$green-darker: darken($green, 20%);

$purple: #3e3e48;
$purple-light: lighten($purple, 10%);
$purple-lighter: lighten($purple, 20%);
$purple-dark: darken($purple, 10%);
$purple-darker: darken($purple, 20%);

$error: #c02f31;
$success: #95c623;
