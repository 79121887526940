@import "styles/_utility";
@import "styles/_colors";

#home {
  height: 100vh;
  background-image: url("../images/gears.png");
  background-size: cover;
  background-position: center top;
  position: relative;

  .container {
    height: 100%;
    padding-top: 200px;
  }
  h1 {
    font-size: 5rem;
    .part1 {
      position: relative;
      opacity: 0;
      top: -60px;
      transition: all 0.3s ease-in-out;
      &.animate {
        opacity: 1;
        top: 0px;
      }
    }
    .part2 {
      position: relative;
      opacity: 0;
      right: -60px;
      transition: all 0.3s ease-in-out 0.4s;
      &.animate {
        opacity: 1;
        right: 0;
      }
    }
  }

  #scrollDown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 1s, bottom 0.3s ease-in-out 1s;

    .line {
      margin: 10px 0;
      height: 30px;
      width: 3px;
      background: $green-darker;
      box-shadow: createShadow(3px, "box");
    }
    svg {
      font-size: 1.5rem;
      color: $green-darker;
      filter: createShadow(1px, "svg");
    }

    &.animate {
      opacity: 1;
      bottom: 60px;
    }
  }
}

@media screen and (max-width: 768px) {
  #home {
    h1 {
      font-size: 3rem;
      padding-left: 10px;
    }
    #scrollDown {
      bottom: 60px;
      &.animate {
        bottom: 120px;
      }
    }
  }
}
