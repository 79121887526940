@import "styles/_utility";
@import "styles/_colors";

.item {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 250px;
	.bar {
		display: block;
		position: relative;
		width: 60px;
		background: linear-gradient(
			to right,
			#7d98a1,
			#7d98a1 30%,
			#606f74 30%,
			#606f74 100%
		);
		.wrapper {
			width: 200px;
			position: absolute;
		}

		.bar-info {
			color: #fff;
			position: absolute;
			background: $green-dark;
			top: 15px;
			padding: 15px 20px;
			max-width: 200px;
			z-index: 101;
			transition: all 0.1s ease-out;
			.full-name {
				overflow-wrap: break-word;
			}
		}
		&.left {
			.wrapper {
				left: 100%;
			}
			.bar-info {
				transform: rotateY(90deg);
				transform-origin: 0% 50%;
				box-shadow: inset 6px 0 6px -3px rgba($dark, 0.5),
					0px 6px 12px -3px rgba($dark, 0.5);
			}
		}
		&.right {
			.wrapper {
				right: 100%;
			}
			.bar-info {
				right: 0;
				transform: rotateY(90deg);
				transform-origin: 100% 50%;
				box-shadow: inset -6px 0 6px -3px rgba($dark, 0.5),
					0px 6px 12px -3px rgba($dark, 0.5);
			}
		}
		&:hover {
			.bar-info {
				transition: all 0.3s ease-out;
			}
			&.left .bar-info {
				transform: rotateY(0deg);
			}
			&.right .bar-info {
				transform: rotateY(0deg);
			}
		}
	}
	.name {
		font-size: 0.9em;
	}
}
@media screen and (max-width: 768px) {
	.item {
		.bar {
			width: 50px;
		}
	}
}
