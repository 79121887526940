@import "styles/_utility";
@import "styles/_colors";

.btn {
  box-shadow: createShadow(3px, "box");
  color: $light;
  padding: calcRem(8px) calcRem(20px);
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:active {
    box-shadow: createShadow(3px, "box"), createShadow(3px, "box", inset);
    color: $grey;
  }
}
