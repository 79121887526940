@import "styles/_utility";
@import "styles/_colors";

.devices {
  perspective: 3651px;
  display: flex;
  justify-content: flex-end;
  --phone-width: 80px;
  .laptop-container,
  .laptop-hotspot,
  .phone-container,
  .phone-hotspot {
    position: absolute;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .laptop-container,
  .laptop-hotspot {
    z-index: 1;
    top: 27px;
    right: calc(var(--phone-width) + 25px);
    width: 575px;
    height: 365px;
    transform: rotateY(-30deg) rotateX(18deg) rotate(3deg);
  }
  .phone-container,
  .phone-hotspot {
    z-index: 3;
    position: absolute;
    top: 214px;
    right: 18px;
    width: 140px;
    height: 252px;
    transform: perspective(1075px) rotateY(26deg) rotateX(14deg) rotate(2deg);
  }
  .laptop-container,
  .phone-container {
    background: $dark;
  }
  .project-laptop,
  .project-phone {
    position: absolute;
    top: 0;
    width: 100%;
    &.hovering {
      opacity: 0.6;
    }
  }
  .play-button {
    display: block;
    position: relative;
    color: $dark;
    font-size: 10em;
    opacity: 0;
    transition: opacity 0.2s;
  }
  .phone-hotspot {
    .play-button {
      font-size: 5em;
    }
  }
  .laptop-hotspot,
  .phone-hotspot {
    z-index: 3;
    cursor: pointer;
  }
  .laptop-hotspot:hover,
  .phone-hotspot:hover {
    .play-button {
      opacity: 0.7;
    }
  }
  .laptop {
    z-index: 2;
    position: relative;
    right: var(--phone-width);
  }
  .phone {
    z-index: 3;
    position: absolute;
    top: 200px;
    right: 0;
  }
}
@media screen and (max-width: 1199px) {
  .devices {
    .laptop-container,
    .laptop-hotspot {
      width: 585px;
      right: calc(var(--phone-width) + 20px);
    }
  }
}
@media screen and (max-width: 991px) {
  .devices {
    .laptop-container,
    .laptop-hotspot,
    .laptop {
      display: none;
    }
    .phone-container,
    .phone-hotspot {
      top: 5px;
      right: 25px;
      width: 248px;
      height: 425px;
      transform: perspective(4195px) rotateY(33deg) rotateX(14deg) rotate(3deg);
    }
    .phone {
      top: -20px;
      height: 500px;
    }
  }
}
@media screen and (max-width: 768px) {
  .devices {
    perspective: 0;
    order: 2;
    .phone-container,
    .phone-hotspot {
      width: 220px;
      height: 473px;
      top: 12px;
      left: 50%;
      margin-left: -110px;
      transform: rotateY(0deg) rotateX(0deg) rotate(0deg);
    }
    .phone {
      top: 0;
      margin: 0 auto;
      position: relative;
    }
  }
}
