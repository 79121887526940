@function calcRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
@function createShadow($width, $type, $inset: null) {
  $shadow: $inset 1px 1px $width rgba($dark, 0.7),
    $inset -1px -1px $width rgba($light, 0.2);
  @if $type == "svg" {
    $shadow: drop-shadow($inset 1px 1px $width rgba($dark, 0.7))
      drop-shadow($inset -1px -1px $width rgba($light, 0.2));
  }

  @return $shadow;
}
