@import "styles/_utility";
@import "styles/_colors";

#about {
  height: 100vh;
  position: relative;
  .band {
    top: 200px;
    width: 100vw;
    height: calcRem(400px);
    background: $green;
    transform: skewY(-6deg);
    position: absolute;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.9),
      -6px -6px 12px rgba(0, 0, 0, 0.9);
  }
  h1 {
    padding-top: 80px;
    text-align: right;
  }
  #info {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .blurb {
      max-width: 550px;
      padding-right: 30px;
      h2 {
        text-align: right;
      }
      p {
        text-align: right;
        color: $grey-light;
      }
    }
    .headshot {
      flex-shrink: 0;
      border-radius: 100px;
      height: 200px;
      width: 200px;
      border: 12px solid $green;
      box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 1),
        inset -2px -2px 6px rgba(lighten($green, 50%), 0.2),
        2px 2px 6px rgba(0, 0, 0, 0.4),
        -2px -2px 6px rgba(lighten($green, 50%), 0.2);
      background-color: $green-darker;
      background-size: cover;
      background-position: right center;
    }
  }
}
@media screen and (max-width: 768px) {
  #about {
    height: auto;
    min-height: 100vh;
    #info {
      align-items: flex-start;
      margin-bottom: 50px;
      .blurb {
        padding-right: 0;
      }
      .headshot {
        display: none;
      }
    }
  }
}
