@import "styles/_utility";
@import "styles/_colors";

@keyframes shake {
  0%,
  100% {
    transform: translateX(0px);
  }
  20%,
  60% {
    transform: translateX(10px);
  }
  40%,
  80% {
    transform: translateX(-10px);
  }
}

.contact-form {
  margin-top: 80px;
  .form-control {
    background: $green;
    color: $grey;
    border: none;
    box-shadow: createShadow(3px, "box");
    &:focus {
      box-shadow: createShadow(3px, "box"), createShadow(3px, "box", inset);
      color: $light;
    }
    &.error {
      border: 2px solid rgba($error, 0.3);
      animation: shake 0.3s;
    }
  }
  textarea {
    height: calcRem(150px);
    resize: none;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: $grey;
    transition: background-color 0s 9999s;
    &:focus {
      -webkit-text-fill-color: $light;
    }
  }
  label {
    display: flex;
    align-items: center;
    .error {
      margin-left: auto;
      font-size: 0.75em;
      color: $error;
    }
  }
  .message {
    &.success {
      color: $success;
    }
    &.error {
      color: $error;
    }
  }
}
@media screen and (max-width: 768px) {
  .contact-form {
    margin-top: 100px;
  }
}
